<template>
  <div class="site-customization--root">
    <alert-modal
      v-if="isPublishModalOpen"
      :title="publishModalTitle"
      :text="publishModalText"
      :primaryBtnText="publishModalConfirmButtonText"
      secondaryBtnText="No, cancel"
      @primaryBtnClick="onPublish"
      @secondaryBtnClick="isPublishModalOpen = false"
      @close="isPublishModalOpen = false"
      :isSuccess="isPublishModalSuccess"
      :dismissible="isPublishModalSuccess"
    />

    <div class="loading--container" v-if="isLoading">
      <img class="rotate" src="../assets/spinner.svg" alt="Loading" />
    </div>

    <template v-else>
      <div class="site-customization--form-blocker" v-if="isPublished">
        To access the tool bar you must unpublish
        <img src="../assets/icon-lock.svg" alt="Lock icon" />
      </div>

      <div class="site-customization--form">
        <customization-form
          ref="customizationForm"
          :clientConfig="clientConfig"
          v-model="customContentConfig"
          @errors="e => formErrors = e"
          @input="saveError = false; saveSuccess = false"
        />
      </div>

      <div class="site-customization--preview">
        <div class="save-container">
          <Alert v-if="saveError" type="error" class="save--alert">
            Please fix the errors below, and try saving again.
          </Alert>
          <Alert v-if="saveSuccess" type="success" class="save--alert">
            You have successfully submitted your work.
          </Alert>
          <b-button class="save" @click="saveCustomContent">Save</b-button>
          <b-button
            class="publish"
            @click="isPublishModalOpen = true; isPublishModalSuccess = false"
            :disabled="shouldDisablePublish && !isPublished"
          >
            {{(isPublished)
                ? 'Unpublish'
                : "Publish"
            }}
          </b-button>
        </div>
        <b-tabs class="site-customization--tabs" v-model="activeTab">
          <template v-if="shouldShowPreview">
            <b-tab-item label="Desktop" :headerClass="isPublished ? 'hidden' : ''">
              <template>
                <oe-preview-default v-if="customContentConfig.selectedTemplate !== 'template-4'" v-bind="customContentConfig" :clientConfig="clientConfig" />
                <oe-preview-slice v-else v-bind="customContentConfig" :clientConfig="clientConfig" />
              </template>
            </b-tab-item>
            <b-tab-item v-if="false" label="Mobile" :headerClass="isPublished ? 'hidden' : ''">
              <oe-preview v-bind="customContentConfig" :clientConfig="clientConfig" isMobile />
            </b-tab-item>
          </template>

          <b-tab-item headerClass="tabs--client-name" disabled>
            <template slot="header">{{ clientName }}</template>
            <alert v-if="isPublished" title="Warning" type="warning">
              Please use the "Unpublish" feature to temporarily disable the OE site and display an "under construction" page instead.
            </alert>
          </b-tab-item>
        </b-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import CustomizationForm from '../components/customization-form/customization-form.vue'
import networkConfigApi from '@/api/networkConfigApi'
import { cloneDeep, get, isEqual } from 'lodash'
import { getDataURIFromString } from '@/helpers'
import Alert from '@/components/alert.vue'
import AlertModal from '@/components/alert-modal.vue'
import OePreviewDefault from '@/components/oe-preview/oe-preview-default.vue'
import OePreviewSlice from '@/components/oe-preview/slice/oe-preview-slice.vue'
import { isSuccessResponse } from '@/helpers/is-success-response'
import { getTemplateDefault, templateFields } from '@/components/customization-form/data'

const defaultLogo = require('../assets/custom-default/default-logo.svg')
const defaultHero = require('../assets/custom-default/default-hero.svg')

const shouldShowPreview = process.env.VUE_APP_SHOW_PREVIEW || false

export default {
  name: 'site-customization',

  components: {
    CustomizationForm,
    Alert,
    AlertModal,
    OePreviewDefault,
    OePreviewSlice
  },

  data () {
    return {
      clientName: this.$route.params.clientName,
      customContentConfig: getTemplateDefault(),
      clientConfig: {},
      formErrors: {},

      activeTab: 0,

      shouldShowPreview,

      saveError: false,
      saveSuccess: false,
      waitingToPublish: false,
      isPublishModalOpen: false,
      isPublishModalSuccess: false,
      isLoading: true,
      shouldDisablePublish: false,

      backendToFrontendMappings: {
        FORMAT_PHONE: 'phone',
        REACT_APP_HEADER_TEXT: 'headline',
        REACT_APP_HERO_IMAGE: 'hero',
        REACT_APP_LOGO: 'logo',
        REACT_APP_NETWORK_OF_EXCELLENCE: 'networkOfExcellence',
        REACT_APP_PHONE: 'phone',
        SHOW_NUMBER_ON_MENU: 'phoneEnabled',
        REACT_APP_TEMPLATE: 'selectedTemplate',
        REACT_APP_WITH_CENTIVO_WILL_GET: 'withCentivo',
        SHOW_ALREADY_MEMBER: 'alreadyMember',
        SHOW_DISCLAIMER: 'disclaimer',
        SHOW_TESTIMONIALS: 'testimonials',
        SHOW_VPC: 'vpc',
        REACT_APP_HERO_BTN: 'heroButton',
        REACT_APP_EXTERNAL_LINK: 'externalLink',
        REACT_APP_DESCRIPTION: 'description',
        VIDEO_URL: 'video',
        VIDEO_ENABLED: 'videoEnabled'
        // REACT_APP_PROVIDER_NETWORK_MAPS: string,
        // ELIGIBILITY_LINK_URL?: string,
        // ELIGIBILITY_LIST_NAME?: string,
        // ELIGIBILITY_ZIP_CODES?: string[],
        // FOOTER_TEXT?: string,
        // REACT_APP_CUSTOM_STYLE?: string,
        // REACT_APP_NETWORK_PLAN_SELECTION: string,
        // REACT_APP_PLAN_NETWORKS: any[],
        // SHOW_CONTACT_FORM: string,
        // SHOW_ELIGIBILITY: string,
        // SHOW_ELIGIBILITY_LINK: string,
        // SHOW_HEADER: string,
        // SHOW_HERO: string,
        // SHOW_MAIN_INFO: string,
        // SHOW_NETWORK_OF_EXCELLENCE: string,
        // SHOW_SEARCH: string,
      },
      frontendToBackendMappings: {
        headline: 'REACT_APP_HEADER_TEXT',
        hero: 'REACT_APP_HERO_IMAGE',
        logo: 'REACT_APP_LOGO',
        networkOfExcellence: 'REACT_APP_NETWORK_OF_EXCELLENCE',
        phone: 'REACT_APP_PHONE',
        phoneEnabled: 'SHOW_NUMBER_ON_MENU',
        selectedTemplate: 'REACT_APP_TEMPLATE',
        withCentivo: 'REACT_APP_WITH_CENTIVO_WILL_GET',
        alreadyMember: 'SHOW_ALREADY_MEMBER',
        disclaimer: 'SHOW_DISCLAIMER',
        testimonials: 'SHOW_TESTIMONIALS',
        vpc: 'SHOW_VPC',
        heroButton: 'REACT_APP_HERO_BTN',
        externalLink: 'REACT_APP_EXTERNAL_LINK',
        description: 'REACT_APP_DESCRIPTION',
        videoEnabled: 'VIDEO_ENABLED',
        video: 'VIDEO_URL'
      }
    }
  },

  methods: {
    async checkURIs (currentImage, defaultImage) {
      if (currentImage === defaultImage) {
        return await getDataURIFromString(currentImage)
      }

      return currentImage
    },

    async formatForBackend (customConfigs) {
      const enabledFields = templateFields[customConfigs.selectedTemplate ?? 'template-1']
      const availableFields = Object.keys(templateFields['template-1'])

      const fieldNames = Object.keys(enabledFields)

      const config = cloneDeep(customConfigs)

      availableFields.forEach(f => {
        if (!fieldNames.includes(f) && f !== 'selectedTemplate') {
          config[f] = null
        }
      })

      const formattedConfig = {}

      Object.keys(config).forEach((key) => {
        if (key.startsWith('heroButton')) {
          formattedConfig.REACT_APP_HERO_BTN = {
            enabled: config.heroButtonEnabled,
            text: config.heroButtonText,
            link: config.heroButtonUrl
          }
          return
        }

        if (key.startsWith('externalLink')) {
          formattedConfig.REACT_APP_EXTERNAL_LINK = {
            enabled: config.externalLinkEnabled,
            label: config.externalLinkLabel,
            url: config.externalLinkUrl
          }
          return
        }

        if (key === 'withCentivo' && Array.isArray(config.withCentivo)) {
          config.withCentivo = config.withCentivo
            .map(e => typeof e !== 'string' || e.trim() === '' ? undefined : e)
        }

        const value = config[key]
        if (this.frontendToBackendMappings[key]) {
          formattedConfig[this.frontendToBackendMappings[key]] = value
        }
      })

      formattedConfig.REACT_APP_LOGO = await this.checkURIs(formattedConfig.REACT_APP_LOGO, defaultLogo)
      formattedConfig.REACT_APP_HERO_IMAGE = await this.checkURIs(formattedConfig.REACT_APP_HERO_IMAGE, defaultHero)

      return formattedConfig
    },

    formatForFrontend (configs = {}) {
      const obj = {}
      Object.keys(configs).forEach((key) => {
        let value = configs[key]

        if (key === 'REACT_APP_HERO_BTN') {
          obj.heroButtonEnabled = value.enabled
          obj.heroButtonText = value.text
          obj.heroButtonUrl = value.link
          return
        }

        if (key === 'REACT_APP_EXTERNAL_LINK') {
          obj.externalLinkEnabled = value.enabled
          obj.externalLinkLabel = value.label
          obj.externalLinkUrl = value.url
          return
        }

        value = value === 'true' ? true : value
        value = value === 'false' ? false : value

        // If phone is already setted by REACT_APP_PHONE don't overwrite with FORMAT_PHONE value
        if (key === 'FORMAT_PHONE' && obj[this.backendToFrontendMappings[key]]) {
          return
        }

        obj[this.backendToFrontendMappings[key] ?? 'keyNotFound'] = value
      })
      return obj
    },

    async saveCustomContent (shouldAlertSuccess = true) {
      this.saveError = false
      this.saveSuccess = false

      const isFormValid = this.$refs.customizationForm.isValid()

      if (!isFormValid) {
        this.saveError = true
        this.isPublishModalOpen = false

        return false
      }

      const formattedCustomConfig = await this.formatForBackend(this.customContentConfig)
      const { status } = await networkConfigApi.updateCustomClientConfig(this.clientName, formattedCustomConfig)

      if (status !== 200 && status !== 204) {
        this.saveError = true
        this.isPublishModalOpen = false

        return false
      }
      this.saveSuccess = shouldAlertSuccess

      return true
    },

    async onPublish () {
      const isPublished = get(this.clientConfig, 'preEnrollment.publish')
      const hasChanges = !isEqual(this.customContentConfig, this.formatForFrontend(this.clientConfig?.customContentConfig))

      const isFormValid = this.$refs.customizationForm.isValid()

      if (!isPublished && hasChanges && isFormValid) {
        const formattedCustomConfig = await this.formatForBackend(this.customContentConfig)
        const { status } = await networkConfigApi.updateCustomClientConfig(this.clientName, formattedCustomConfig)

        if (status !== 200 && status !== 204) {
          return
        }
      }

      let response = null
      if (isPublished) {
        response = await networkConfigApi.unpublishOESite(this.clientName)
      } else {
        const isSuccess = await this.saveCustomContent(false)

        if (!isSuccess) {
          return
        }

        response = await networkConfigApi.publishOESite(this.clientName)
      }

      if (isSuccessResponse(response)) {
        this.clientConfig = response.data
        this.isPublishModalSuccess = true
      }
    }
  },

  async mounted () {
    const { data: clientConfig = {} } = await networkConfigApi.getClient(this.clientName)
    const { customContentConfig = {} } = clientConfig

    this.customContentConfig = {
      ...getTemplateDefault(customContentConfig.REACT_APP_TEMPLATE),
      ...this.formatForFrontend(customContentConfig)
    }

    this.clientConfig = clientConfig
    this.isLoading = false
  },

  computed: {
    isPublished () {
      return !!this.clientConfig &&
        !!this.clientConfig.preEnrollment &&
        !!this.clientConfig.preEnrollment.publish
    },

    publishModalText () {
      // NOTE: Notice that the success message is inverted.
      // This is because it will be resolved after the action is performed,
      // resolving to the correct one
      if (!this.isPublished) {
        if (this.isPublishModalSuccess) { return 'The pre-enrollment site has been unpublished.' }
        return 'If you publish the pre-enrollment site it will be accessible to the public.'
      }
      if (this.isPublishModalSuccess) { return 'The pre-enrollment site has been published.' }
      return 'This should be used in case clients request any retroactive visual customizations or changes to the OE site URL.'
    },
    publishModalTitle () {
      return this.isPublished ? 'Are you sure you want to unpublish?' : 'Confirm publishing'
    },
    publishModalConfirmButtonText () {
      return this.isPublished ? 'Yes, unpublish' : 'Yes, publish'
    }
  },
  watch: {
    isPublished: function (newVal) {
      if (this.shouldShowPreview) {
        this.activeTab = newVal ? 2 : 0
      }
    },
    customContentConfig: {
      handler: function (newVal) {
        if (
          (
            this.customContentConfig.selectedTemplate === 'template-4' &&
            newVal.externalLinkEnabled &&
            (!newVal?.externalLinkLabel || !newVal?.externalLinkUrl)
          ) ||
          (
            this.customContentConfig.selectedTemplate === 'template-1' &&
            newVal.heroButtonEnabled &&
            (!newVal.heroButtonText || !newVal.heroButtonUrl)
          )
        ) {
          this.shouldDisablePublish = true
        } else {
          this.shouldDisablePublish = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang='scss'>
div.site-customization--root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  font-family: 'Centivo-Regular';
  color: #444;

  .loading--container {
    width: 100%;
    padding-top: calc(40vh - 5rem);
    height: 100%;
    display: flex;

    img {
      margin: auto;
      height: 5rem;
    }
  }

  button.save {
    background-color: #ec008c;
    border: none;
    color: #fff;
    font-weight: 700;
    padding: 0 2.5rem;
    height: 3.2rem;
    margin: 1rem 1.25rem;
    align-self: flex-start;
  }

  button.publish {
    background-color: transparent;
    border-color: #ec008c;
    color: #ec008c;
    font-weight: 700;
    padding: 0 2.5rem;
    height: 3.2rem;
    align-self: flex-start;
    margin: 1rem 0;
  }

  .site-customization--preview {
    min-width: calc(100% - 23rem);

    .alert.warning {
      margin: 1rem 2.5rem;
      text-align: left;
    }
  }

  .save-container {
    display: flex;
    align-items: flex-end;
    padding: 0 2.5rem 0 0;
    background-color: white;
    position: absolute;
    right: 0;
    margin-left: auto;

    button.save {
      background-color: #ec008c;
      border: none;
      color: #fff;
      font-weight: 700;
      padding: 0 2.5rem;
      height: 3.2rem;
      margin: 1rem 1.25rem;
      align-self: flex-start;
    }

    button.publish {
      background-color: transparent;
      border-color: #ec008c;
      color: #ec008c;
      font-weight: 700;
      padding: 0 2.5rem;
      height: 3.2rem;
      align-self: flex-start;
      margin: 1rem 0;
    }

    .alert {
      margin: 1rem 0;
    }
  }

  div.site-customization--form {
    width: 23rem;
    padding: 4.5rem 1.6rem 0 2.5rem;
    flex-shrink: 0;
  }
  div.site-customization--form-blocker {
    position: absolute;
    top: 3rem;
    height: calc(100% - 3rem);
    width: 23rem;
    background-color: #00000077;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 5rem 1.625rem 0 2.5rem;
    text-align: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;

    img {
      height: 5.7rem;
      margin-top: 1rem;
    }
  }

  .site-customization--tabs {
    flex: 1;
    height: 100%;

    .hidden {
      display: none;
    }

    .tab-content {
      background-color: #EBEFF5;
      padding: 3.5rem;
      height: calc(100% - 5.25rem);
    }

    .tabs--client-name {
      opacity: 1 !important;

      a {
        line-height: 1rem;
        border: none;
        padding-left: 2rem;
        font-weight: 600;
        font-size: 1.25rem;
        color: #444;
      }
    }

    .tabs ul {
      padding-top: 3rem;
      padding-left: 1rem;
    }

    .tabs li:not(.tabs--client-name) {
      border: 1px solid #00a69e;
      border-bottom: none;
      width: 9.25rem;

      a,
      a:hover {
        font-weight: 700;
        color: #00a69e;
        border-bottom: none;
      }

      &:not(:last-child) {
        margin-right: 0.125rem;
      }

      &.is-active {
        background-color: #00a69e;

        a {
          color: #fff;
        }
      }

      &.is-disabled {
        border: none;
        background-color: #ebebeb;
        cursor: not-allowed;
        opacity: 1 !important;

        a {
          pointer-events: all;
          color: #bbbdbf;
        }
        a:active {
          pointer-events: none;
        }
      }

      .tooltip-content {
        width: 20rem;
        white-space: pre-wrap;
        margin-bottom: 0.5rem;
        font-weight: 600;
        text-align: left;
        border-radius: 2px;
        padding: .5rem;
      }
    }
  }
}
</style>
